<template>
  <CToast
      :key="'export_' + payload.id"
      :show="true"
      header="Выгрузка"
      color="success"
  >
    <div v-if="loading">
      <CProgress
          :value="percent"
          color="success"
          animated
          class="mt-1"
      />
    </div>
    <div v-else>
      Выгрузка завершена <b><a @click.prevent="download" href="#">скачать</a></b>
    </div>
  </CToast>
</template>

<script>
import ExportHelper from '@/views/exports/ExportHelper'
import NotificationTypes from '@/components/NotificationTypes'

export default {
  name: 'DownloadExport',
  data() {
    return {
      percent: 0,
      loading: true
    }
  },
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  methods: {
    download() {
      ExportHelper.downloadFile(this.payload.id, 'export.xlsx')
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if ((mutation.type === 'socket/ADD_MESSAGE') && (mutation.payload.id = this.payload.id)) {
        if (mutation.payload.type === NotificationTypes.EXPORT_END) {
          this.loading = false
        } else if (mutation.payload.type === NotificationTypes.EXPORT_PROCESS) {
          this.percent = mutation.payload.percent
        }
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  }
}
</script>

import api from './api'

export default {
    getNew() {
        return api.get('/exports-new')
    },
    download(id) {
        return api.get('/export-download/' + id, { responseType: 'blob' })
    },
    getAll() {
        return api.get('/exports')
    },
    getLast(limit) {
        return api.get('/exports-last/' + limit)
    },
    perform(type, data) {
        return api.post('/export-perform/' + type, data)
    }
}

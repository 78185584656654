<template>
  <CToaster>
    <template v-for="message in messages">
      <DownloadExport :key="message.id" :payload="message"/>
    </template>
  </CToaster>
</template>

<script>
import DownloadExport from '@/components/notifications/DownloadExport'
import NotificationTypes from '@/components/NotificationTypes'

export default {
  name: 'Notification',
  components: { DownloadExport },
  data() {
    return {
      messages: [],
      unsubscribe: null
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'socket/ADD_MESSAGE') {
        if (mutation.payload.type === NotificationTypes.EXPORT_START) {
          this.messages.push(mutation.payload)
        }
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  }
}
</script>

<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('common/TOGGLE_SIDEBAR_MOBILE')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('common/TOGGLE_SIDEBAR_DESKTOP')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
<!--      <CHeaderNavItem class="px-3">-->
<!--        <CHeaderNavLink to="/dashboard">-->
<!--          Dashboard-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
<!--      <CHeaderNavItem class="px-3">-->
<!--        <CHeaderNavLink to="/users" exact>-->
<!--          Users-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
<!--      <CHeaderNavItem class="px-3">-->
<!--        <CHeaderNavLink>-->
<!--          Settings-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
<!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
<!--        <CHeaderNavLink>-->
<!--          <CIcon name="cil-bell"/>-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
<!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
<!--        <CHeaderNavLink>-->
<!--          <CIcon name="cil-list"/>-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <router-link to="exports">
            <CIcon size="lg" name="cil-cloud-download"/><CBadge v-if="newMessages > 0" color="success">{{newMessages}}</CBadge>
          </router-link>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumb :items="breadcrumbs" class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import exportApi from '@/api/export'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data() {
    return {
      newExports: [],
      exportsPolling: null
    }
  },
  methods: {
    loadNewExports() {
      exportApi.getNew().then(r => this.newExports = r.data)
    }
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['common/breadcrumbs']
    },
    newMessages() {
      return this.$store.getters['socket/getMessages'].length
    }
  },
  created() {
    this.loadNewExports()
//    this.exportsPolling = setInterval(this.loadNewExports, 5 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.exportsPolling)
  }
}
</script>

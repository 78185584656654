export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Главная',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Данные']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Покупатели',
        to: '/customers',
        icon: 'cil-group'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Товары',
        to: '/products',
        icon: 'cil-gift'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Продажи',
        to: '/sales',
        icon: 'cil-cart'
      },
//      {
//        _name: 'CSidebarNavTitle',
//        _children: ['Components']
//      },
//      {
//        _name: 'CSidebarNavDropdown',
//        name: 'Base',
//        route: '/base',
//        icon: 'cil-puzzle',
//        items: [
//          {
//            name: 'Breadcrumbs',
//            to: '/base/breadcrumbs'
//          },
//          {
//            name: 'Cards',
//            to: '/base/cards'
//          },
//          {
//            name: 'Carousels',
//            to: '/base/carousels'
//          },
//          {
//            name: 'Collapses',
//            to: '/base/collapses'
//          },
//          {
//            name: 'Forms',
//            to: '/base/forms'
//          },
//          {
//            name: 'Jumbotrons',
//            to: '/base/jumbotrons'
//          },
//          {
//            name: 'List Groups',
//            to: '/base/list-groups'
//          },
//          {
//            name: 'Navs',
//            to: '/base/navs'
//          },
//          {
//            name: 'Navbars',
//            to: '/base/navbars'
//          },
//          {
//            name: 'Paginations',
//            to: '/base/paginations'
//          },
//          {
//            name: 'Popovers',
//            to: '/base/popovers'
//          },
//          {
//            name: 'Progress Bars',
//            to: '/base/progress-bars'
//          },
//          {
//            name: 'Switches',
//            to: '/base/switches'
//          },
//          {
//            name: 'Tables',
//            to: '/base/tables'
//          },
//          {
//            name: 'Tabs',
//            to: '/base/tabs'
//          },
//          {
//            name: 'Tooltips',
//            to: '/base/tooltips'
//          }
//        ]
//      },
//      {
//        _name: 'CSidebarNavDropdown',
//        name: 'Buttons',
//        route: '/buttons',
//        icon: 'cil-cursor',
//        items: [
//          {
//            name: 'Buttons',
//            to: '/buttons/standard-buttons'
//          },
//          {
//            name: 'Button Dropdowns',
//            to: '/buttons/dropdowns'
//          },
//          {
//            name: 'Button Groups',
//            to: '/buttons/button-groups'
//          },
//          {
//            name: 'Brand Buttons',
//            to: '/buttons/brand-buttons'
//          }
//        ]
//      },
//      {
//        _name: 'CSidebarNavItem',
//        name: 'Charts',
//        to: '/charts',
//        icon: 'cil-chart-pie'
//      },
//      {
//        _name: 'CSidebarNavDropdown',
//        name: 'Icons',
//        route: '/icons',
//        icon: 'cil-star',
//        items: [
//          {
//            name: 'CoreUI Icons',
//            to: '/icons/coreui-icons',
//            badge: {
//              color: 'info',
//              text: 'NEW'
//            }
//          },
//          {
//            name: 'Brands',
//            to: '/icons/brands'
//          },
//          {
//            name: 'Flags',
//            to: '/icons/flags'
//          }
//        ]
//      },
//      {
//        _name: 'CSidebarNavDropdown',
//        name: 'Notifications',
//        route: '/notifications',
//        icon: 'cil-bell',
//        items: [
//          {
//            name: 'Alerts',
//            to: '/notifications/alerts'
//          },
//          {
//            name: 'Badges',
//            to: '/notifications/badges'
//          },
//          {
//            name: 'Modals',
//            to: '/notifications/modals'
//          }
//        ]
//      },
//      {
//        _name: 'CSidebarNavItem',
//        name: 'Widgets',
//        to: '/widgets',
//        icon: 'cil-calculator',
//        badge: {
//          color: 'primary',
//          text: 'NEW',
//          shape: 'pill'
//        }
//      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      }
    ]
  }
]

<template>
  <CFooter :fixed="false">
    <div>
      <div style="display: inline-block;margin-left: 5px;">
        <a href="https://extremstyle.ua" target="_blank">Extremstyle</a>
        <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
      </div>
      <div style="display: inline-block" class="ml-2">
        <CIcon v-if="socketConnected" class="text-success" name="cil-wifi-signal-0"/>
        <CIcon v-else class="text-danger" name="cil-wifi-signal-off"/>
      </div>
    </div>
    <div class="mfs-auto">
      <span class="mr-1">Created by</span>
      <a href="https://artweb.ua" target="_blank">ArtWeb</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    socketConnected() {
      return this.$store.getters['socket/connected']
    }
  }
}
</script>

import exportApi from '@/api/export'

const ExportHelper = {
    downloadFile(id, fileName) {
        exportApi.download(id)
                 .then(response => {
                     const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                     const link = document.createElement('a')
                     link.href = URL.createObjectURL(blob)
                     link.download = fileName
                     link.click()
                     URL.revokeObjectURL(link.href)
                 }).catch(console.error)
    }
}

export default ExportHelper
<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('common/set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <h3 class="c-sidebar-brand-full"><span style="color: #50ae34">Extrem</span> style</h3>
      <h2 class="c-sidebar-brand-minimized"><span style="color: #50ae34">E</span></h2>
      <!--      <CIcon-->
<!--        class="c-sidebar-brand-full"-->
<!--        name="logo"-->
<!--        size="custom-size"-->
<!--        :height="35"-->
<!--        viewBox="0 0 556 134"-->
<!--      />-->
<!--      <CIcon-->
<!--        class="c-sidebar-brand-minimized"-->
<!--        name="logo"-->
<!--        size="custom-size"-->
<!--        :height="35"-->
<!--        viewBox="0 0 110 134"-->
<!--      />-->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('common/set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show () {
      return this.$store.getters['common/sidebarShow']
    },
    minimize () {
      return this.$store.getters['common/sidebarMinimize']
    }
  }
}
</script>
